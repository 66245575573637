import React, { useState } from 'react'
import { Alert, Spin } from 'antd'
import { fetchAPI } from '@/utils/api'
import { Form, Input, InputNumber, Button, Select } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import './DrivingLessonBooking.less'
import { navigate } from 'gatsby'
const stripePromise = loadStripe('pk_test_BF19fSOgt65djfwhOUdR5fRQ00A1m0FBoD')
const { Option } = Select
const ROUTES = [
    {
        id: 'hobart',
        label: 'Hobart',
    },
    {
        id: 'glenorchy',
        label: 'Glenorchy',
    },
    {
        id: 'bellerive',
        label: 'Bellerive',
    },
    {
        id: 'sorell',
        label: 'Sorell',
    },
    {
        id: 'kingston',
        label: 'Kingston',
    },
    {
        id: 'huonville',
        label: 'Huonville',
        tocontact: true,
    },
    {
        id: 'newnorfolk',
        label: 'New Norfolk',
        tocontact: true,
    },
]
const P1AssessmentBooking = ({ data }) => {
    console.log(data)
    const [showFeedback, setShowFeedback] = useState(false)
    const [showError, setError] = useState(false)
    const [loading, setLoader] = useState(false)
    const [contactMessage, setShowContactMessage] = useState()
    const onFinish = async values => {
        try {
            setLoader(true)
            const response = await fetchAPI('/driving-lesson-bookings', {
                method: 'POST',
                body: JSON.stringify({
                    name: values.user.name,
                    email: values.user.email,
                    phone: values.user.phone,
                    address: values.user.address,
                    message: values.user.message,
                    licensenumber: values.user.licensenumber,
                    route: values.user.route,
                    emailconfirmation: data.emailconfirmation,
                    type: data.type,
                }),
            })
            setLoader(false)
            window.open(
                'https://qualitycaredrivingschool.square.site',
                '_blank',
            )
            navigate('/')
        } catch (err) {
            console.log(err)
            setLoader(false)
            setError(true)
        }
    }

    const handlePackageChange = value => {
        if (value) {
            console.log(value)
            // setLessonPackage(value)
            const selectedRoute = ROUTES.find(rp => rp.id == value)
            console.log(selectedRoute,'pl')
            if(selectedRoute && selectedRoute.tocontact){
                setShowContactMessage('Please contact at 0401 483 487 for booking.')
            }else {
                setShowContactMessage('')
            }
        }
    }

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    }

    return (
        <div className="py-10 text-center p1-assement-booking m-auto max-w-4xl">
            <h1 className="text-3xl mb-10 font-bold mb-2">{data.title}</h1>

            <div className="flex flex-col items-center">
                <Form
                    className={'lead-form w-full'}
                    layout={'vertical'}
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        name={['user', 'name']}
                        label="Name"
                        rules={[{ required: true }]}
                        className=""
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'email']}
                        label="Email"
                        rules={[{ type: 'email', required: true }]}
                        className=" "
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'phone']}
                        label="Phone Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'licensenumber']}
                        label="Drivers Licence Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Licence Number!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['user', 'address']}
                        label="Your Address Suburb on your Drivers Licence"
                        className="w"
                        rules={[
                            {
                                required: true,
                                message: 'Please add your address',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={['user', 'package']}
                        label="Select Location"
                        className=""
                        rules={[
                            {
                                required: true,
                                message: 'Please select location',
                            },
                        ]}
                    >
                        <Select
                            defaultValue={'Select your preferred location'}
                            onChange={handlePackageChange}
                        >
                            {ROUTES.map(pd => {
                                return (
                                    <Option value={pd.id}>{pd.label}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    {contactMessage && (
                        <Form.Item>
                            <div>{`${contactMessage}`}</div>
                        </Form.Item>
                    )}

                    <Form.Item
                        name={['user', 'message']}
                        label="Your Message"
                        className=""
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item className=" flex-col sm:flex-row">
                        <Button
                            className={'w-full '}
                            type={'primary'}
                            disabled={loading || contactMessage}
                            size={'large'}
                            htmlType="submit"
                        >
                            {loading ? 'Loading...' : 'Pay Now'}
                        </Button>
                    </Form.Item>
                </Form>

                {showFeedback && (
                    <Alert
                        message="Thank you"
                        description={
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.feedbackmessage,
                                }}
                            />
                        }
                        type="info"
                    />
                )}
                {showError && (
                    <Alert
                        message="Error occurred"
                        type="error"
                        description={
                            <div>
                                {' '}
                                Something went wrong. Please correct your
                                information above.
                            </div>
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default P1AssessmentBooking
